import React, { useState } from 'react';
import LoadingAnimation from './LoadingAnimation';  // Import the LoadingAnimation component

const TailoredMindfulness = () => {
  const [text, setText] = useState('');
  const [modelResponse, setModelResponse] = useState('');
  const [audioSrc, setAudioSrc] = useState('');  // State for audio source
  const [isLoading, setIsLoading] = useState(false);  // State for loading
  const [duration, setDuration] = useState(8);  // State for session duration with a default value

  const handleInputChange = (e) => {
    setText(e.target.value);
  };
    

  const handleDurationChange = async (e) => {
    setDuration(e.target.value);
    await fetch(`/update-session-duration/?duration=${encodeURIComponent(e.target.value)}`);
  };


  const handlePlayButtonClick = async () => {
    setIsLoading(true);  // Start loading
    const response = await fetch(`/get-text-response?text=${encodeURIComponent(text)}&duration=${encodeURIComponent(duration)}`);
    const data = await response.json();
    setModelResponse(data.response);

    // Set the audio source after getting the model response
    const audioUrl = `/stream-silence-mp3?text=${encodeURIComponent(data.response)}&duration=${encodeURIComponent(duration)}`;
    setAudioSrc(audioUrl);

    setIsLoading(false);  // Stop loading
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Tailored Meditation Session</h1>
      <input
        type="text"
        placeholder="Enter topic for the meditation session"
        value={text}
        onChange={handleInputChange}
        style={styles.input}
      />
      <div style={styles.sliderContainer}>
        <label style={styles.label}>Session Duration: {duration} minutes</label>
        <input
          type="range"
          min="1"
          max="60"
          value={duration}
          onChange={handleDurationChange}
          style={styles.slider}
        />
      </div>  
      
      <button onClick={handlePlayButtonClick} style={styles.button}>
        Generate Meditation
      </button>
      {isLoading ? (
        <LoadingAnimation />  // Show loading animation
      ) : (
        modelResponse && audioSrc && (  // Show media player instead of response container
          <audio controls style={styles.audio}>
            <source src={audioSrc} type="audio/wav" />
            Your browser does not support the audio element.
          </audio>
        )
      )}
    </div>
  );
};

// Simple styles object for the components
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f5f5f5',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    fontSize: '2rem',
    marginBottom: '20px',
    color: '#333',
  },
  input: {
    padding: '10px',
    fontSize: '1rem',
    marginBottom: '20px',
    width: '80%',
    maxWidth: '400px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
    sliderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
    width: '80%',
    maxWidth: '400px',
  },
  label: {
    marginBottom: '10px',
    fontSize: '1rem',
    color: '#333',
  },
  slider: {
    width: '100%',
  },

  button: {
    padding: '10px 20px',
    fontSize: '1rem',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginBottom: '20px',
  },
  audio: {
    width: '80%',
    maxWidth: '400px',
  },
};

export default TailoredMindfulness;

