import React from 'react';                                                                                                     
                                                                                                                               
import './LoadingAnimation.css'; // Make sure to create this CSS file                                                          

                                                                                                                                
 function LoadingAnimation() {                                                                                                  
     return (                                                                                                                   
         <div className="loading-container">                                                                                    
             <div className="loading-circle"></div>                                                                             
         </div>                                                                                                                 
     );                                                                                                                         
 }   

export default LoadingAnimation;

