import React from 'react';
import TailoredMindfulness from './TailoredMindfulness';

function App() {
  return (
    <div className="App">
      <TailoredMindfulness />
    </div>
  );
}

export default App;

